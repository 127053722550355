<template>
  <div
    class="tab-pane fade col-md-12"
    :id="idLinkDirect"
    role="tabpanel"
    :aria-labelledby="`${idLinkDirect}-tab`"
  >
    <div class="container p-0">
      <div class="col p-0">
        <label for="name">Colar Link</label>
        <input
          class="form-control form-control-lg"
          id="name"
          type="text"
          placeholder="URL do video do youtube"
          v-model="formData.link"
        />
      </div>

      <div class="row mt-2">
        <div class="mt-2 mb-2 p-3">
          <img
            v-if="!formData.link"
            class="img pl-3 pr-3"
            src="@/assets/image/iconesUpload/youtube_icone.png"
            alt="youtube_icone.png"
          />
          <iframe
            v-else
            width="266"
            height="167"
            :src="replaceString(formData.link, '/watch?v=', '/embed/')"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <div class="col mt-2">
          <div class="col mb-2 p-0">
            <label for="name"><strong>Título</strong> (Obrigatório) </label>
            <input
              class="form-control form-control-lg"
              id="name"
              type="text"
              placeholder="Nome"
              v-model="formData.name"
            />
          </div>
          <div class="col mb-4 p-0">
            <label for="Descrição" class="labelText">Descrição</label>
            <div class="col mb-12 p-0">
              <textarea
                name="Descrição"
                id="Descrição"
                cols="2"
                rows="5"
                placeholder="Descrição"
                v-model="formData.description"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["formData", "idLinkDirect"],
  mounted() {
    console.log("id", this.idLinkDirect);
  }
};
</script>
<style scoped>
* {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
}

.bgLabel::after {
  background-color: #f38235 !important;
  color: #fff !important;
  height: 39px;
  display: flex;
  align-items: center;
}

input {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #797984;
  border: 1px solid #98c4e6;
}

.inputFile label {
  cursor: pointer;
  height: 40px;
}

label {
  font-weight: 400;
  color: #797984 !important;
}

textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #98c4e6;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #797984;
  height: 85px;
}

.labelText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #797984;
}

.img {
  width: 266px;
}
</style>
